import * as React from "react";

import Puzzle from "../../Puzzle";

import kaluze from "../../images/kaluze.jpg";
import map from '../../images/m2.png'
import audio from '../../../static/a2.mp3'

const Page = () => {
  return (
    <Puzzle
      title="Zagadka 2"
      author="Autorka: Alicja Patanowska"
      imageSrc={kaluze}
      imageAlt="kałuża na chodniku"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      audio={audio}
      language="pl"
      languageUrl="/riddles/lwpUGPgfu5"
    >
      <div>
        <p>
          Poczekaj na deszcz. Kałuże pozwolą Ci lepiej zrozumieć miasto, w
          którym mieszkasz.
        </p>
        <p>Dlaczego kałuże?</p>
        <p>
          Tam gdzie jest glina, są i kałuże. Glina to ilasta skała osadowa o
          niewielkiej przepuszczalności, dzięki czemu na jej powierzchni długo
          utrzymuje się woda. Zarówno Wrocław, jak i cały region zostały
          zbudowane na glebach o dużej zawartości iłów, a iły, obok kwarcu i
          skalenia, są głównym materiałem wchodzącym w skład gliny, z której
          wytwarza się ceramikę. Stąd województwo dolnośląskie nazywane jest
          zagłębiem ceramicznym.
        </p>
        <p>Dlaczego woda?</p>
        <p>
          Przenieśmy się na chwilę do drugiej połowy lat 70., kiedy po raz
          pierwszy dostrzeżono, że sąsiedzkie podwórko galerii SIC! jest
          międzygatunkowym ekosystemem. Wtedy właśnie architekt krajobrazu
          Janusz Szymański, odpowiedzialny za projekt zieleni Kościuszkowskiej
          Dzielnicy Mieszkaniowej, zwrócił uwagę na rolę wody jako integralnej
          części biosfery w mieście. Dzielnica, którą miał za zadanie
          zagospodarować, to „pierwszy, zastosowany tytułem eksperymentu
          przypadek wprowadzenia do wnętrz blokowych zwierciadeł wody z
          wodotryskami”. Punktem wyjścia dla jego rozważań było założenie, że
          „wnętrze (…) powinno spełniać te zadania, które w innych, lepszych
          warunkach spełnia ogród przydomowy”<sup>1</sup>. Ilość wody na naszej
          planecie jest stała, zmienia się jedynie jej rozmieszczenie, a wraz z
          nim zmianom ulega także środowisko naturalne. „Betonowe pustynie”, jak
          obecnie nazywa się szczelnie wyłożone kostką, asfaltem i płytami
          betonowymi miasta oraz uszczelnione koryta rzek, przyczyniają się do
          zmniejszenia retencji wody opadowej do zaledwie ok. 6%<sup>2</sup>.
          Coraz częstsze ulewy, które są jedną z konsekwencji zmian
          klimatycznych, zamiast wsiąkać w glebę, odpływają do kanalizacji.
          Powodzie i lokalne podtopienia związane ze spływem wód deszczowych
          stają się coraz częstszym zjawiskiem w polskich miastach.Miejska
          roślinność, oczka wodne, kałuże i tereny zielone pozwalają w jakimś
          stopniu absorbować wodę, chroniąc nas przed powodziami oraz suszą.
          Dlatego tak istotne jest „odbetonowanie” miasta w celu umożliwienia
          gromadzenia się wody (retencji), przesiąkania do gruntu (infiltracji),
          podczyszczania oraz odparowania. Dziś w gospodarstwie domowym
          wykorzystujemy wyłącznie wodę pitną – nie tylko do picia i higieny
          osobistej, ale również do spłukiwania toalet, podlewania roślin, mycia
          aut i innych powierzchni. Polska niestety nie dysponuje dużymi
          zasobami wody pitnej. W skali światowej jej ilość porównywana jest do
          zasobów Egiptu. Szacuje się, że już około 2050 roku możemy zacząć
          odczuwać skutki ograniczonego dostępu do wody pitnej<sup>3</sup>.
          Rozwiązanie tego problemu podpowiada nam sama natura.
        </p>
        <p>
          Wszystkie organizmy żyjące na Ziemi potrzebują wody. Niech od dziś
          przypomina nam o tym każda napotkana kałuża.
        </p>
        <hr />
        <ol>
          <li>
            "Mój Dom", dodatek do "Przeglądu Gospodarczego", nr. 43, Wrocław
            1976, s. 5
          </li>
          <li>
            Prof. Antoni J. Dziatkowiak. Źródło:
            https://naturalnie.com.pl/zatrzymac-wode/ . Dostęp: 26.06.2021
          </li>
          <li>
            Źródło:
            https://www.nik.gov.pl/aktualnosci/polska-pustynia-europy.html .
            Dostęp: 20.06.2021
          </li>
        </ol>
      </div>
    </Puzzle>
  );
};

export default Page;
